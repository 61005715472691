import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box, } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Question from '../dashboard/Question';
function StudyCollapsibleRow(_a) {
    var study = _a.study, integration = _a.integration, attributes = _a.attributes, clientName = _a.clientName, DASHBOARDS = _a.DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, envName = _a.envName;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { onClick: function () { return setOpen(!open); }, style: { cursor: 'pointer' } },
            React.createElement(TableCell, null, study.name),
            React.createElement(TableCell, { align: "right" }, open ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null))),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 2 },
                React.createElement(Collapse, { in: open, timeout: "auto", unmountOnExit: true },
                    React.createElement(Box, { margin: 1, height: 400, overflow: "auto" },
                        React.createElement(Question, { questionName: "".concat(study.name, " ").concat(integration.name, " Last Synced"), questionId: study.question_id, envName: envName, clientName: clientName, attributes: attributes })))))));
}
function CollapsibleRow(_a) {
    var integration = _a.integration, attributes = _a.attributes, clientName = _a.clientName, DASHBOARDS = _a.DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, envName = _a.envName;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { onClick: function () { return setOpen(!open); }, style: { cursor: 'pointer' } },
            React.createElement(TableCell, null,
                React.createElement("img", { src: integration.icon, height: "25px", width: "auto", alt: integration.name })),
            React.createElement(TableCell, { align: "right" }, open ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null))),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 2 },
                React.createElement(Collapse, { in: open, timeout: "auto", unmountOnExit: true },
                    React.createElement(Box, { margin: 1 },
                        React.createElement(Table, null,
                            React.createElement(TableBody, null, integration.studies.map(function (study) { return (React.createElement(StudyCollapsibleRow, { key: study.question_id, study: study, integration: integration, attributes: attributes, clientName: clientName, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, envName: envName })); })))))))));
}
var DataIntegrations = function (_a) {
    var INTEGRATIONS_QUESTIONS = _a.INTEGRATIONS_QUESTIONS, attributes = _a.attributes, clientName = _a.clientName, DASHBOARDS = _a.DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, envName = _a.envName;
    return (React.createElement(TableContainer, { component: Paper },
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Integration"),
                    React.createElement(TableCell, { align: "right" }))),
            React.createElement(TableBody, null, Object.values(INTEGRATIONS_QUESTIONS).map(function (integration) { return (React.createElement(CollapsibleRow, { key: integration.name, integration: integration, attributes: attributes, clientName: clientName, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, envName: envName })); })))));
};
export default DataIntegrations;
