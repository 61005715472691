import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import styled from '@emotion/styled';
var StyledToolbar = styled(Toolbar)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b['&.MuiToolbar-root'] = {
            minHeight: '50px',
        },
        _b);
});
var Header = function (_a) {
    var attributes = _a.attributes, clientLogo = _a.clientLogo, clientName = _a.clientName;
    var userEmail = get(attributes, 'email', '');
    return (React.createElement(AppBar, { position: "static", color: "transparent", elevation: 0 },
        React.createElement(StyledToolbar, null,
            React.createElement(Box, { display: "flex", alignItems: "center", flexGrow: 1 },
                React.createElement(Link, { to: "/sponsor/home", style: { textDecoration: 'none' } },
                    React.createElement("img", { src: "/".concat(clientLogo), alt: "Logo", width: "70px" }))),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end" },
                React.createElement(Typography, { variant: "body2", fontWeight: "bold" }, clientName),
                React.createElement(Typography, { variant: "body2", color: "textSecondary" }, userEmail)))));
};
export default Header;
