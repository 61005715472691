var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { MetabaseIFrame } from "./MetabaseIFrame";
import { getDashboardJWT, getMetabaseJWT } from '../../api/jwt';
import { METABASE_SITE_URL, NEXT_PUBLIC_AUTH_URL } from '../../utils/consts';
import { logUserAction } from '../../api/audit';
import { get } from 'lodash';
var Dashboard = function (_a) {
    var dashboardId = _a.dashboardId, cardId = _a.cardId, tabId = _a.tabId, attributes = _a.attributes, children = _a.children, DASHBOARDS = _a.DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, clientName = _a.clientName, envName = _a.envName, integration_dashboards = _a.integration_dashboards;
    var _b = useState(null), userJWT = _b[0], setUserJWT = _b[1];
    var _c = useState(null), mbJWT = _c[0], setMbJWT = _c[1];
    useEffect(function () {
        var fetchDashboardData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dashboardData, dashboardName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dashboardData = Object.values(DASHBOARDS).find(function (data) { return data.dashboard_id === dashboardId; }) ||
                            Object.values(SPECIAL_DASHBOARDS).find(function (data) { return data.dashboard_id === dashboardId; }) ||
                            Object.values(integration_dashboards !== null && integration_dashboards !== void 0 ? integration_dashboards : {}).find(function (data) { return data.dashboard_id === dashboardId; });
                        dashboardName = (dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.name) || 'Unknown';
                        if (!!get(dashboardData, 'fullName', '')) {
                            dashboardName = get(dashboardData, 'fullName', '');
                        }
                        if (!(envName === 'main')) return [3, 2];
                        return [4, logUserAction(clientName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', 'VIEW_DASHBOARD', "Viewed dashboard ".concat(dashboardName, " (ID: ").concat(dashboardId, ")"), 'DASHBOARD_INTERACTION', { dashboardId: dashboardId, dashboardName: dashboardName })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); };
        fetchDashboardData();
    }, [dashboardId]);
    useEffect(function () {
        var fetchUserJWT = function () { return __awaiter(void 0, void 0, void 0, function () {
            var jwt;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, getDashboardJWT(attributes, envName)];
                    case 1:
                        jwt = _a.sent();
                        setUserJWT(jwt);
                        return [2];
                }
            });
        }); };
        fetchUserJWT();
    }, []);
    var payload = {
        resource: { dashboard: dashboardId },
        params: {},
        exp: Math.round(Date.now() / 1000) + (8 * 60 * 60)
    };
    useEffect(function () {
        var fetchMbJWT = function () { return __awaiter(void 0, void 0, void 0, function () {
            var jwt;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, getMetabaseJWT(payload, envName)];
                    case 1:
                        jwt = _a.sent();
                        setMbJWT(jwt);
                        return [2];
                }
            });
        }); };
        fetchMbJWT();
    }, []);
    var staticUrl = "".concat(METABASE_SITE_URL, "/embed/dashboard/").concat(mbJWT, "#bordered=true&titled=true");
    var dynamicUrl = "".concat(NEXT_PUBLIC_AUTH_URL, "/oauth/metabase?dashboard_id=").concat(dashboardId, "&user_jwt=").concat(userJWT);
    if (tabId) {
        dynamicUrl += "&tab_id=".concat(tabId);
    }
    return userJWT ? React.createElement(React.Fragment, null,
        React.createElement(MetabaseIFrame, { staticURL: staticUrl, authURL: dynamicUrl }),
        children) : React.createElement(Box, { textAlign: "center", mt: 2 },
        React.createElement(CircularProgress, { color: "secondary" }));
};
export default Dashboard;
